<template>
  <div class="ui grid container-4" id="scrty">
    <div class="two column row stackable">
      <div class="column div-1">
        <div class="left">
          <img class="" src="@/assets/group1.png" alt="Tayocash">
        </div>
      </div>
      <div class="column div-2">
        <div class="right">
          <h1>Your <span>Security</span> is our Top Priority.</h1>

          <p>TayoCash is licensed and regulated by the
          <span>Bangko Sentral ng Pilipinas,</span> registered with the <span>Anti-Money Laundering Council,</span> and certified
          by the <span>National Privacy Commission</span>.</p>

          <p>We also partnered with leading service providers worldwide like <span>Amazon Web Services</span> and <span>ComplyAdvantage</span> to ensure the safety of your information and transactions.</p>
          <div class="border"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content4'
}
</script>

<style scoped lang='scss'></style>
